@use '../../node_modules/@angular/material/index' as mat;

@include mat.core();
$light-primary: mat.define-palette(mat.$blue-palette, 700);
$light-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
// The "warn" palette is optional and defaults to red if not specified.
//$light-warn: mat.define-palette(mat.$red-palette);
// Define custom typography using Poppins font
// Define your custom typography configuration
$mat-typography-config: (font-family: 'Poppins', );
$my-light-theme:mat.define-light-theme((
color:(
primary:$light-primary,
accent:$light-accent,
  //typography: mat.define-typography-config($mat-typography-config),),
  density:0,
typography:$mat-typography-config , // Apply custom typography to theme
  //typography: mat.define-typography-config($mat-typography-config),),

)
));
//My theme2-

$primary: #263238; //navbar color
$menu-bg: #fdfdfd;
$menu-text:black;
$accent: #fbc02d;
// Dark Theme
// Define a dark theme
//$dark-theme: mat.define-dark-theme((
//        color: (
//                primary: mat.define-palette(mat.$amber-palette),
//                accent: mat.define-palette(mat.$blue-grey-palette),
//        ),
  // Only include `typography` and `density` in the default dark theme.
  //      typography: mat.define-typography-config(),
  //      density: 0,
//));


// Emit theme-dependent styles for common features used across multiple components.
//@include mat.core-theme($my-light-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
//@include mat.button-theme($my-light-theme);

// Include the theme mixins for other components you use here.
//@include mat.all-component-themes($dark-theme);


// Apply the dark theme by default
@include mat.core-theme($my-light-theme);
//@include mat.button-theme($dark-theme);

// Apply the light theme only when the user prefers light themes.
//@media (prefers-color-scheme: light) {
//  // Use the `-color` mixins to only apply color styles without reapplying the same
//  // typography and density styles.
//  @include mat.core-top($my-light-theme);
  @include mat.button-color($my-light-theme);
//}
