/* Change the background color of the MatSnackBar */
.custom-snackbar {
  background-color: #efe7c5; /* Your desired background color */
  color: #0b5ba8; /* Your desired text color */

}
.success {
  background-color: #0b7a10; /* Your desired background color */
  color: white; /* Your desired text color */

}
.error {
  background-color: #b4024a; /* Your desired background color */
  color: white; /* Your desired text color */

}
.info {
  background-color: #024fb4; /* Your desired background color */
  color: white; /* Your desired text color */

}

.snackbar-warn {
  background-color: #af1111; /* Your desired background color */
  color: white; /* Your desired text color */

}
/* Change the text color of the MatSnackBar */
.custom-snackbar .mat-snack-bar-container {
  color: #0b5ba8; /* Your desired text color */
}
