/* You can add global styles to this file, and also import other style files */
@import "assets/theme";
//@font-face {
//  font-family: 'Poppins';
//  src: url("src/assets/fonts/poppins/poppins-v5-latin-regular.woff2") format('woff2'),
//  url('assets/fonts/poppins/poppins-v5-latin-regular.woff2') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Poppins';
//  src: url('src/assets/fonts/poppins/poppins-v5-latin-regular.woff2') format('woff2'),
//  url('src/assets/fonts/poppins/poppins-v5-latin-regular.woff2') format('woff');
//  font-weight: bold;
//  font-style: normal;
//}
html, body { height: 100%; }
body { margin: 0;
}
$background-color: #af1111 !default;
$myColor: #b6b9b9;
$my-primary: #0b5ba8;
$my-primary-dark: #263238; //navbar color
*{
  box-sizing: border-box;
}

.mat-table{
  //background: #8e8c87;
  color: white;
}


.mat-toolbar.mat-primary {
  background: #2c2929;
}
.mat-header-cell {

  font-size: 12px;
}
th.mat-header-cell{
  font-size: 12px;

}
th tr {
  text-align: center;
}
tr{
  //text-align: center;
}
//.mat-list{
//  background: $my-primary-dark;
//  color: #fff;
//}
//.mat-link{
//  color: lightgrey;
//}


button[mat-icon-button] {
  $large-size-button: 80px;
  $large-size-icon: 80px;

  &.icon-button-large {
    width: $large-size-button;
    height: $large-size-button;
    line-height: $large-size-button;

    .mat-icon {
      font-size: $large-size-icon;
      width: $large-size-icon;
      height: $large-size-icon;
      line-height: $large-size-icon;
    }

    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
}
mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  { padding: 0.4em 0px }
mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper { top: -1.5em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75);
  width: 133.33333%;
}
.rounded{
  border-radius: 100px;
  background: $accent;
  color: black;
  padding: 5px;
}
.rounded-1{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
  margin-bottom: 10px;
}
//
//Donor ICON Avatar
.example-header-image {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/acceife-fbdb5.appspot.com/o/icons%2Freceipt-ico.png?alt=media&token=652aaeb3-fe40-4c6c-b720-82c6250fb9e5');
  background-size: cover;
}
.example-spacer {
  flex: 1 1 auto;
}
.text-shadow{
  text-shadow: 1px 1px 1px $my-primary,
  1px 2px 1px $my-primary,
  1px 10px 3px rgba(16, 16, 16, 0.5),
  1px 15px 5px rgba(16, 16, 16, 0.4),
  //1px 20px 30px rgba(16, 16, 16, 0.3),
  //1px 25px 50px rgba(16, 16, 16, 0.2);
}
//calendar

.fc-daygrid-event {
  border-radius: 3px;
  font-size: var(--fc-small-font-size);
  position: relative;
  white-space: pre;
  color: crimson;
}
.fc  { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
  & .fc-daygrid-day-number {
    padding: 4px;
    position: relative;
    z-index: 4;
    color: blue;
    font-weight: 600;
  }
}
.ng-star-inserted{
  //color: #82D44E;
  white-space: normal;
}
.fc-event.fc-event-draggable, .fc-event[href] {
  cursor: pointer;
  color: black;
  //white-space: normal;
}
.fc-daygrid-event.fc-daygrid-dot-event.ng-star-inserted{
  font-size: larger;color: #4c97cb;
}
.fc-daygrid-day-events-count{
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
}
.msg{
  background-color: #f5f2e8;
  border: 2px  black;
  color: grey;
  padding: 4px;
  text-align: center;
}
.fc-daygrid-day-top{
  display: block;
}
.fc-list-event-time{
  //background-color: white;
  color: transparent;
}
//** FOR SElECT PADDING BOTTOM IN GROUP EDUCATION TABLE
.no-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}
// *GLOBAL TABLE STYLE
//.mat-header-cell{
//  margin-right: 10px;
//  background: black;
//  color: #e1b60b;
//}
//.mat-cell{
//  margin-right: 15px;
//  background: #7d7d8a;
//  color:  #e1b60b;
//}
.example-spacer {
  flex: 1 ;
}

//table  style
//table {
//  width: 100%;
//  margin-bottom: 1rem;
//  background-color: #fff;
//  border: 1px solid #dee2e6;
//  border-collapse: collapse;
//}

table th,
table td {

  vertical-align: middle!important;
  //text-align: center!important;
  border: 0.1px solid rgba(222, 226, 230, 0.43);
  padding-left: 0.5rem!important;
  padding-right: 0.5rem!important;
}
//
//table thead th {
//  background-color: rgba(0, 0, 0, 0.05);
//}

table tbody tr:nth-of-type(odd) {
  background-color: rgba(175, 170, 170, 0.05);
}

//
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Adjust this value based on your column width */
}
// select option searching code
.mat-select-search{
  padding-left: 10px;
  /* padding: 5px; */
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid;
  margin: 7px;
  border-radius: 5px;
}
table {
  width: 100%;
}



